import comingSoon1 from "../assets/images/comingSoon1.svg";
import comingSoon2 from "../assets/images/comingSoon2.svg";
// import logo512 from "../assets/images/logo512.png";
import AnimatedPages from "./Global/Animation";
import SecondLine from "./Global/SecondLine";
import SeparatedLine from "./Global/SeparatedLine";

const Registry = () => {
  return (
    <AnimatedPages>
      <div className="flex flex-wrap items-between">
        <SeparatedLine />
        <div className="w-full flex items-center py-10">
          <div className="md:w-3/12  md:flex xs:hidden justify-end">
            <img className="w-[60%]" src={comingSoon1} alt="comingSoon1" />
          </div>

          <div className="md:w-6/12 xs:w-full md:px-16 text-center ease-in duration-200 flex flex-wrap items-center justify-center px-5 my-10">
            <div className="w-full md:hidden xs:flex justify-center mb-5">
              <img className="w-32" src={comingSoon1} alt="comingSoon1" />
            </div>
            <h2 className="mb-5 uppercase text-primary font-semibold md:text-3xl xs:text-xl tracking-[10px]">
              Registry
            </h2>
            <p className="w-full md:text-lg xs:text-normal  uppercase font-normal text-primary me-5">
              Your presence on our special day is the greatest present of all!
              However, if you do wish to celebrate with a gift, we have created
              a very simple registry below.
            </p>
            <p className="mt-4 w-full md:text-lg xs:text-normal  uppercase font-bold text-primary me-5">
              please address any gift cards to <br /> hello@natasha-yassin.com{" "}
              <br />
              <p className="mt-4">
                Delivery address: <br /> 70 holland St.
                <br /> apartment c1902 se1 9nx
              </p>
            </p>
            <div class="w-full flex flex-wrap justify-center mt-20">
              <div class="md:w-[50%] xs:w-full  px-5">
                <div class="w-full flex flex-wrap justify-center">
                  <a
                    class="block w-[80px] rounded-xl"
                    href="https://www.harrods.com/en-gb/shopping/gift-cards"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      class="w-full"
                      src="https://www.harrods.com/static/harrods/public/static/media/safari-pinned-tab.1f0d4d0a.svg"
                      alt="logo512"
                    />
                  </a>
                  <div class="w-full mt-5 text-sm uppercase font-normal text-primary">
                    to give a harrods gift card, please click the icon above
                  </div>
                </div>
              </div>

              <div class="md:w-[50%] xs:w-full md:mt-0 xs:mt-5 px-5">
                <div class="w-full flex flex-wrap justify-center">
                  <a
                    class="block w-[80px] rounded-xl"
                    href="https://www.selfridges.com/GB/en/features/info/gift-card/"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      class="w-full"
                      src="https://www.selfridges.com/GB/en/features/etc/designs/zg/selfridges/desktop/assets/img/favicon.ico"
                      alt="logo512"
                    />
                  </a>
                  <div class="w-full mt-5 text-sm uppercase font-normal text-primary">
                    to give a selfridges gift card, please click the icon above
                  </div>
                </div>
              </div>

              <div class="md:w-[50%] xs:w-full mt-5 px-5">
                <div class="w-full flex flex-wrap justify-center">
                  <a
                    class="block w-[80px] rounded-xl"
                    href="https://prezola.com/buy/view/198579"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      class="w-full"
                      src="https://cdn-icons-png.flaticon.com/512/9030/9030761.png"
                      alt="logo512"
                    />
                  </a>
                  <div class="w-full mt-5 text-sm uppercase font-normal text-primary">
                    If you'd like to give cash, you can do this through Prezola
                    by clicking the icon above
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="md:w-3/12 md:flex xs:hidden">
            <img className="w-[60%]" src={comingSoon2} alt="comingSoon2" />
          </div>
        </div>

        <div className="w-full self-end">
          <SecondLine />
        </div>
      </div>
    </AnimatedPages>
  );
};
export default Registry;
